import { Controller } from "stimulus";
import Turbolinks from "turbolinks";

export default class extends Controller {
  initialize() {
    this.element.addEventListener("ajax:beforeSend", function(event) {
      const options = event.detail[1];
      Turbolinks.visit(options.url);
      event.preventDefault();
    });
  }
}
