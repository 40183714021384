import { Controller } from "stimulus";
import ReactDOM from "react-dom";
import React from "react";
import MultiSelect from "components/MultiSelect";

export default class extends Controller {
  get options() {
    return JSON.parse(this.data.get("options"));
  }

  get selected() {
    return JSON.parse(this.data.get("selected")) || [] ;
  }

  get isMulti() {
    return JSON.parse(this.data.get("is-multi"));
  }

  get isCreatable() {
    return JSON.parse(this.data.get("is-creatable"));
  }

  get inputName() {
    return this.data.get("input-name");
  }

  connect() {
    this.renderComponent();
  }

  renderComponent() {
    const _selected = this.selected;
    const _options = this.options.map(pair => {
      return { label: pair[0], value: pair[1] };
    });
    const _value = _options.filter(({ value }) => _selected.includes(value))

    ReactDOM.render(
      <MultiSelect
        isMulti={this.isMulti}
        options={_options}
        defaultValue={_value}
        name={this.inputName}
        isCreatable={this.isCreatable}
      />,
      this.element
    );
  }
}
