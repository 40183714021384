import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

config.mutateApproach = "sync";

import {
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faYoutube
);

dom.watch();
