import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default ({
  startDateLabel,
  endDateLabel,
  initialStartDate,
  initialEndDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  function startDateHandler(newStartDate) {
    onStartDateChange(newStartDate);
    setStartDate(newStartDate);
  }

  function endDateHandler(newEndDate) {
    onEndDateChange(newEndDate);
    setEndDate(newEndDate);
  }

  return (
    <div className="flex items-center">
      <div className="flex flex-col mx-1">
        <label className="mb-2">{startDateLabel}</label>
        <DatePicker
          selected={startDate}
          onChange={startDateHandler}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="bp3-input"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport",
            },
          }}
        />
      </div>
      <div className="flex flex-col mx-1">
        <label className="mb-2">{endDateLabel}</label>
        <DatePicker
          selected={endDate}
          onChange={endDateHandler}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="bp3-input"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport",
            },
          }}
        />
      </div>
    </div>
  );
};
