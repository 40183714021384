import { Controller } from "stimulus";
import ReactDOM from "react-dom";
import React from "react";
import u from "umbrellajs";
import MultiSelect from "components/MultiSelect";

export default class extends Controller {
  static targets = ["cards", "multiselect", "divider"];

  get cardsEl() {
    return this.cardsTarget;
  }

  get multiselectEl() {
    return this.multiselectTarget;
  }

  get dividerEl() {
    return this.dividerTarget;
  }

  get options() {
    return JSON.parse(this.data.get("options"));
  }

  get inputName() {
    return this.multiselectEl.dataset["inputName"];
  }

  initialize() {
    this.offerCards = new Map();
    this.onChange = this.onChange.bind(this);
  }

  connect() {
    this.renderComponent();
  }

  renderComponent() {
    const _options = this.options.map(pair => {
      return { label: pair[0], value: pair[1] };
    });

    ReactDOM.render(
      <MultiSelect
        isMulti={true}
        options={_options}
        name={this.inputName}
        onChange={this.onChange}
      />,
      this.multiselectEl
    );
  }

  onChange(_change, actionData) {
    switch (actionData.action) {
      case "select-option":
        this.addCard(actionData.option.value);
        this.showDivider();
        break;
      case "remove-value":
        this.removeCard(actionData.removedValue.value);
        break;
      case "clear":
        Array.from(this.offerCards.keys()).forEach(id => this.removeCard(id));
        this.hideDivider();
        break;
    }
  }

  async addCard(offerId) {
    const cardHTML = await this.fetchCard(offerId);
    const cardEl = u(document.createElement("div"))
      .addClass("w-full md:w-1/4 p-3")
      .append(u(cardHTML));

    u(this.cardsEl).prepend(cardEl);
    this.offerCards.set(offerId, cardEl);
  }

  removeCard(offerId) {
    const cardEl = this.offerCards.get(offerId);
    cardEl.remove();
    this.offerCards.delete(offerId);
  }

  async fetchCard(offerId) {
    const resp = await fetch(`/offer_cards/${offerId}`);
    const data = await resp.text();

    return data;
  }

  showDivider() {
    const el = u(this.dividerEl);
    if (el.hasClass("hidden")) el.removeClass("hidden");
  }

  hideDivider() {
    const el = u(this.dividerEl);
    if (!el.hasClass("hidden")) el.addClass("hidden");
  }
}
