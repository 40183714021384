import { Controller } from "stimulus";
import ReactDOM from "react-dom";
import React from "react";
import MultiSelect from "components/MultiSelect";

export default class extends Controller {
  static targets = ["multiSelect", "form"];

  get offer_codes() {
    return JSON.parse(this.data.get("offer-codes"));
  }

  initialize() {
    this.formInputs = new Map();
    this.onChange = this.onChange.bind(this);
  }

  connect() {
    const options = this.offer_codes.map(code_pair => {
      return { label: code_pair[0], value: code_pair[1] };
    });

    ReactDOM.render(
      <MultiSelect options={options} onChange={this.onChange} />,
      this.multiSelectTarget
    );
  }

  onChange(selected, actionData) {
    if (actionData.action == "select-option") {
      this.addCode(actionData.option.value);
    } else if (actionData.action == "remove-value") {
      this.removeCode(actionData.removedValue.value);
    } else if (actionData.action == "clear") {
      this.removeAll();
    }
  }

  addCode(code) {
    const input = document.createElement("input");
    input.setAttribute("type", "text");
    input.setAttribute("value", code);
    input.setAttribute("name", this.formTarget.dataset.name);
    input.setAttribute("class", "hidden");

    this.formInputs.set(code, input);

    this.formTarget.appendChild(input);
  }

  removeCode(code) {
    this.formTarget.removeChild(this.formInputs.get(code));
  }

  removeAll() {
    for (let inputElement of this.formInputs.values()) {
      debugger;
      this.formTarget.removeChild(inputElement);
    }
  }
}
