import { Controller } from "stimulus";

export default class extends Controller {
  updatePaginationLimit(e) {
    const limit = e.target.value;
    if (
      "URLSearchParams" in window &&
      [5, 15, 50, 100].includes(parseInt(limit))
    ) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set("limit", limit);
      window.location.search = searchParams.toString();
    }
  }
}
