import { Controller } from "stimulus";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = [
    "container",
    "toggleIcon",
    "offerableSelect",
    "landFilters",
    "houseFilters",
    "apartmentFilters",
    "esterahaFilters",
    "buildingFilters",
    "offerFilters",
  ];

  get container() {
    return this.containerTarget;
  }

  get toggleIcon() {
    return this.toggleIconTarget;
  }

  get offerableSelect() {
    return this.offerableSelectTarget;
  }

  get landFilters() {
    return this.landFiltersTarget;
  }

  get houseFilters() {
    return this.houseFiltersTarget;
  }

  get apartmentFilters() {
    return this.apartmentFiltersTarget;
  }

  get esterahaFilters() {
    return this.esterahaFiltersTarget;
  }

  get buildingFilters() {
    return this.buildingFiltersTarget;
  }

  get offerFilters() {
    return this.offerFiltersTarget;
  }

  initialize() {
    this.disableAllFilterSets();
    this.enableSelectedFilterSet();
    this.element.addEventListener("ajax:beforeSend", function(event) {
      const options = event.detail[1];
      Turbolinks.visit(options.url);
      event.preventDefault();
    });
  }

  toggle() {
    if (this.container.classList.contains("hidden")) {
      this.container.classList.remove("hidden");
      this.toggleIcon.innerHTML = "clear";
    } else {
      this.container.classList.add("hidden");
      this.toggleIcon.innerHTML = "filter_list";
    }
  }

  onPropertyTypeChange(event) {
    this.disableAllFilterSets();
    this.enableOfferFilters();
    this.enableSelectedFilterSet();
  }

  enableOfferFilters() {
    this.offerFilters.classList.remove("hidden");
  }

  disableAllFilterSets() {
    this.disableFilterSet(this.landFilters);
    this.disableFilterSet(this.houseFilters);
    this.disableFilterSet(this.apartmentFilters);
    this.disableFilterSet(this.buildingFilters);
    this.disableFilterSet(this.esterahaFilters);
  }

  enableSelectedFilterSet() {
    switch (this.offerableSelect.value) {
      case "Land":
        this.enableFilterSet(this.landFilters);
        break;
      case "House":
        this.enableFilterSet(this.houseFilters);
        break;
      case "Apartment":
        this.enableFilterSet(this.apartmentFilters);
        break;
      case "Esteraha":
        this.enableFilterSet(this.esterahaFilters);
        break;
      case "Building":
        this.enableFilterSet(this.buildingFilters);
    }
  }

  disableFilterSet(target) {
    if (!target.classList.contains("hidden")) {
      target.classList.add("hidden");
    }
    for (const t of Array.from(target.querySelectorAll("input, select"))) {
      t.disabled = true;
    }
  }

  enableFilterSet(target) {
    if (target.classList.contains("hidden")) {
      target.classList.remove("hidden");
    }
    for (const t of Array.from(target.querySelectorAll("input, select"))) {
      t.disabled = false;
    }
  }
}
