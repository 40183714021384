import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "streetsSelect",
    "northStreetWidthInput",
    "eastStreetWidthInput",
    "westStreetWidthInput",
    "southStreetWidthInput",
    "northFacingCheckbox",
    "eastFacingCheckbox",
    "westFacingCheckbox",
    "southFacingCheckbox",
  ];

  get streetsSelectEl() {
    return this.streetsSelectTarget;
  }

  get streetsValue() {
    const selectedIndex = this.streetsSelectEl.selectedIndex;
    return this.streetsSelectEl.options[selectedIndex].value;
  }

  get checkedCount() {
    return Object.values(this.facingDirecionCheckbox).filter(el => el.checked)
      .length;
  }

  get facingDirecionCheckbox() {
    return {
      north: this.northFacingCheckboxTarget,
      east: this.eastFacingCheckboxTarget,
      west: this.westFacingCheckboxTarget,
      south: this.southFacingCheckboxTarget,
    };
  }

  get streetWidthInput() {
    return {
      north: this.northStreetWidthInputTarget,
      east: this.eastStreetWidthInputTarget,
      west: this.westStreetWidthInputTarget,
      south: this.southStreetWidthInputTarget,
    };
  }

  reset() {
    for (const direction of ["north", "east", "west", "south"]) {
      this.facingDirecionCheckbox[direction].checked = false;
      this.disableStreetWidthInput(direction);
    }
  }

  streetsChange(_e) {
    if (this.streetsValue < this.checkedCount) this.reset();
  }

  enableStreetWidthInput(direction) {
    this.streetWidthInput[direction].disabled = false;
  }

  disableStreetWidthInput(direction) {
    this.streetWidthInput[direction].value = "";
    this.streetWidthInput[direction].disabled = true;
  }

  toggleCheckbox(e) {
    const direction = e.target.dataset.direction;
    if (e.target.checked && this.checkedCount <= this.streetsValue) {
      this.enableStreetWidthInput(direction);
    } else if (!e.target.checked) {
      this.disableStreetWidthInput(direction);
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
  }
}
