import { Controller } from "stimulus";
import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = ["toggleIcon", "form"];

  get form() {
    return this.formTarget;
  }

  get toggleIcon() {
    return this.toggleIconTarget;
  }

  initialize() {
    this.form.addEventListener("ajax:beforeSend", function(event) {
      const options = event.detail[1];
      Turbolinks.visit(options.url);
      event.preventDefault();
    });
  }

  toggle() {
    if (this.form.classList.contains("hidden")) {
      this.form.classList.remove("hidden");
      this.toggleIcon.innerHTML = "clear";
    } else {
      this.form.classList.add("hidden");
      this.toggleIcon.innerHTML = "filter_list";
    }
  }
}
