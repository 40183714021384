import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["menu", "nav"];

  toggle() {
    if (this.menuTarget.classList.contains("hidden")) {
      this.menuTarget.classList.remove("hidden");
      this.navTarget.classList.add("h-screen");
    } else {
      this.menuTarget.classList.add("hidden");
      this.navTarget.classList.remove("h-screen");
    }
  }
}
