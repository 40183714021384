import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "mapContainer",
    "latInput",
    "lngInput",
    "locationInput",
    "currentLocationButton",
  ];

  get mapContainer() {
    return this.mapContainerTarget;
  }

  get latInput() {
    return this.latInputTarget;
  }

  get lngInput() {
    return this.lngInputTarget;
  }

  get locationInput() {
    return this.locationInputTarget;
  }

  get currentLocationButton() {
    return this.currentLocationButtonTarget;
  }

  initialize() {
    this.handleLocationError = this.handleLocationError.bind(this);
    this.useCurrentLocation = this.useCurrentLocation.bind(this);
    this.findLocation = this.findLocation.bind(this);
  }

  connect() {
    this.latlng = this.newLatLng();
    this.map = this.newMap();
    this.marker = this.newMarker();

    this.marker.addListener("dragend", () => {
      this.latlng = this.marker.getPosition();
      this.latInput.value = this.latlng.lat();
      this.lngInput.value = this.latlng.lng();
      this.updateLocation(this.latlng.lat(), this.latlng.lng());
    });

    google.maps.event.addListener(this.map, "dblclick", e => {
      this.latlng = e.latLng;
      this.marker.setPosition(this.latlng);
      this.latInput.value = this.latlng.lat();
      this.lngInput.value = this.latlng.lng();
      this.updateLocation(this.latlng.lat(), this.latlng.lng());
    });
  }

  newLatLng() {
    return new google.maps.LatLng(this.latInput.value, this.lngInput.value);
  }

  newMap() {
    return new google.maps.Map(this.mapContainer, {
      center: this.latlng,
      disableDoubleClickZoom: true,
      zoom: 14,
    });
  }

  newMarker() {
    return new google.maps.Marker({
      position: this.latlng,
      animation: google.maps.Animation.DROP,
      map: this.map,
      draggable: true,
    });
  }

  updateLocation(lat, lng) {
    this.locationInput.value = [lat, lng].join(",");
  }

  findLocation() {
    let [lat, lng] = this.locationInput.value.split(",");
    if (!lat || !lng) return;
    this.latInput.value = lat;
    this.lngInput.value = lng;
    this.latlng = this.newLatLng();
    this.map.setCenter(this.latlng);
    this.marker.setPosition(this.latlng);
  }

  useCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.map.setCenter(pos);
          this.marker.setPosition(pos);
          this.latInput.value = pos.lat;
          this.lngInput.value = pos.lng;
          this.updateLocation(pos.lat, pos.lng);
        },
        () => {
          this.handleLocationError(true, this.map.getCenter());
        }
      );
    } else {
      this.handleLocationError(false, this.map.getCenter());
    }
  }

  handleLocationError(browserHasGeolocation, pos) {
    let infoWindow = new google.maps.InfoWindow();

    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    infoWindow.open(this.map);
  }
}
