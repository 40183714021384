import { Controller } from "stimulus";
import ReactDOM from "react-dom";
import React from "react";
import ImageGallery from "components/ImageGallery";

export default class extends Controller {
  static targets = ["container", "img"];

  initialize() {
    this.urlIndicies = new Map();

    this.removeComponent = this.removeComponent.bind(this);
  }

  connect() {
    this.images = [];
    this.imgTargets.forEach((img, index) => {
      this.images.push({
        original: img.dataset.originalUrl,
        thumbnail: img.dataset.thumbnailUrl,
      });
      this.urlIndicies.set(img.dataset.url, index);
    });

    this.cacheImages();
  }

  onImageClick(event) {
    const imageIndex = this.urlIndicies.get(event.target.dataset.url);

    ReactDOM.render(this.getComponent(imageIndex), this.containerTarget);
  }

  removeComponent() {
    ReactDOM.unmountComponentAtNode(this.containerTarget);
  }

  getComponent(imageIndex) {
    return (
      <ImageGallery
        images={this.images}
        selectedIndex={imageIndex}
        onClose={this.removeComponent}
      />
    );
  }

  async cacheImages() {
    // cache images so signed image urls are not expired when gallery is opened
    this.images.forEach(image => {
      let originalImage = new Image();
      let thumbnailImage = new Image();
      originalImage.src = image.original;
      thumbnailImage.src = image.thumbnail;
    });
  }
}
