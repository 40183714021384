import React from "react";
import ReactModal from "react-modal";
import ImageGallery from "react-image-gallery";

import "image-gallery.css";

function Modal(props) {
  return (
    <ReactModal
      {...props}
      isOpen={true}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "0px 50px",
        },
        content: {
          position: "static",
          backgroundColor: "black",
          padding: "0px",
          border: "0px",
        },
      }}
    >
      {props.children}
    </ReactModal>
  );
}

export default ({ images, selectedIndex, onClose }) => {
  return (
    <Modal onRequestClose={onClose}>
      <div className="bg-black flex flex-col">
        <button onClick={onClose} className="w-5 float-right">
          <i className="material-icons text-white text-2xl p-4">close</i>
        </button>
        <ImageGallery
          items={images}
          startIndex={selectedIndex}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </div>
    </Modal>
  );
};
