import { Controller } from "stimulus";
import ReactDOM from "react-dom";
import React from "react";
import Turbolinks from "turbolinks";
import { subMonths, format, parse } from "date-fns";
import RangeDatePicker from "components/RangeDatePicker";

export default class extends Controller {
  static targets = [
    "form",
    "duration",
    "startDate",
    "endDate",
    "dateRangeContainer",
  ];

  get form() {
    return this.formTarget;
  }

  get durationSelect() {
    return this.durationTarget;
  }

  get startDateInput() {
    return this.startDateTarget;
  }

  get endDateInput() {
    return this.endDateTarget;
  }

  get dateRangeContainerDiv() {
    return this.dateRangeContainerTarget;
  }

  initialize() {
    this.onDurationSelect = this.onDurationSelect.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.showDatePicker = this.showDatePicker.bind(this);
    this.hideDatePicker = this.hideDatePicker.bind(this);
  }

  connect() {
    this.form.addEventListener("ajax:beforeSend", this.onFormSubmit);
    this.durationSelect.addEventListener("change", this.onDurationSelect);

    ReactDOM.render(this.datePickerComponent(), this.dateRangeContainerDiv);

    if (this.durationSelect.value === "0") this.showDatePicker();
  }

  disconnect() {
    this.form.removeEventListener("ajax:beforeSend", null);
    this.durationSelect.removeEventListener("change", null);

    ReactDOM.unmountComponentAtNode(this.dateRangeContainerDiv);
  }

  datePickerComponent() {
    let initialStartDate, initialEndDate;

    if (this.startDateInput.value) {
      initialStartDate = parse(
        this.startDateInput.value,
        "dd/MM/yyyy",
        new Date()
      );
    }

    if (this.endDateInput.value) {
      initialEndDate = parse(this.endDateInput.value, "dd/MM/yyyy", new Date());
    }

    return (
      <RangeDatePicker
        initialStartDate={initialStartDate}
        initialEndDate={initialEndDate}
        startDateLabel={this.data.get("start-date-label")}
        endDateLabel={this.data.get("end-date-label")}
        onStartDateChange={this.onStartDateChange}
        onEndDateChange={this.onEndDateChange}
      />
    );
  }

  onDurationSelect(event) {
    if (event.target.value === "0") {
      this.showDatePicker();
    } else {
      this.hideDatePicker();

      const endDate = Date.now();
      const startDate = subMonths(endDate, parseInt(event.target.value));
      this.startDateInput.value = format(startDate, "dd/MM/yyyy");
      this.endDateInput.value = format(endDate, "dd/MM/yyyy");
    }
  }

  onStartDateChange(date) {
    this.startDateInput.value = date ? format(date, "dd/MM/yyyy") : date;
  }

  onEndDateChange(date) {
    this.endDateInput.value = date ? format(date, "dd/MM/yyyy") : date;
  }

  onFormSubmit(event) {
    const url = event.detail[1].url.split("?")[0];
    const params = new URLSearchParams(event.detail[1].data);
    for (let [k, v] of params) {
      if (v.length === 0) params.delete(k);
    }

    if (this.durationSelect.value !== "0") {
      params.delete("start_date");
      params.delete("end_date");
    }

    Turbolinks.visit(`${url}?${params.toString()}`);
    event.preventDefault();
  }

  showDatePicker() {
    if (this.dateRangeContainerDiv.classList.contains("hidden")) {
      this.dateRangeContainerDiv.classList.remove("hidden");
    }
  }

  hideDatePicker() {
    if (!this.dateRangeContainerDiv.classList.contains("hidden")) {
      this.dateRangeContainerDiv.classList.add("hidden");
    }
  }
}
