import { Controller } from "stimulus";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import AwsS3 from "@uppy/aws-s3";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

export default class extends Controller {
  static targets = [
    "modalButton",
    "fileList",
    "fileInput",
    "fileCount",
    "mainUploadButton",
    "mainUploadIdInput",
  ];

  get modalButton() {
    return this.modalButtonTarget;
  }

  get fileList() {
    return this.fileListTarget;
  }

  get fileCount() {
    return this.fileCountTarget;
  }

  get mainUploadButton() {
    return this.mainUploadButtonTarget;
  }

  get mainUploadButtons() {
    return this.mainUploadButtonTargets;
  }

  get mainUploadIdInput() {
    return this.mainUploadIdInputTarget;
  }

  bindFunctions() {
    this.getUploadParameters = this.getUploadParameters.bind(this);
    this.onUploadSuccess = this.onUploadSuccess.bind(this);
    this.onUploadComplete = this.onUploadComplete.bind(this);
    this.addFileToDOM = this.addFileToDOM.bind(this);
    this.getFileInputName = this.getFileInputName.bind(this);
    this.serializeFileData = this.serializeFileData.bind(this);
    this.onMainUploadClick = this.onMainUploadClick.bind(this);
  }

  initialize() {
    this.newFileCount = 0;
    this.bindFunctions();

    this.uppy = Uppy({
      debug: false,
      autoProceed: false,
      restrictions: {
        maxFileSize: 31457280, // 30 megabytes
        allowedFileTypes: ["image/*", "video/*"],
      },
    });

    this.uppy.use(AwsS3, {
      getUploadParameters: this.getUploadParameters,
    });

    this.uppy.on("upload-success", this.onUploadSuccess);
    this.uppy.on("complete", this.onUploadComplete);
  }

  connect() {
    this.uppy.use(Dashboard, {
      trigger: this.modalButton,
      showProgressDetails: true,
    });
  }

  getUploadParameters(file) {
    const url = "/uploads/presign?filename=" + file.name + "&type=" + file.type;
    return fetch(url)
      .then(function(response) {
        return response.json();
      })
      .then(function(data) {
        return {
          method: data.method,
          url: data.url,
          fields: data.fields,
        };
      });
  }

  serializeFileData(file) {
    return JSON.stringify({
      id: file.meta["key"].match(/^cache\/(.+)/)[1],
      storage: "cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    });
  }

  addFileToDOM(file) {
    const fileInputElement = document.createElement("input");
    fileInputElement.type = "hidden";
    fileInputElement.name = this.getFileInputName(file);
    fileInputElement.value = this.serializeFileData(file);
    this.fileList.insertAdjacentElement("beforeend", fileInputElement);
    this.newFileCount++;
  }

  onUploadSuccess(file, _response) {
    this.addFileToDOM(file);
  }

  onUploadComplete(result) {
    const count = result.successful.length;
    // TODO: localize
    this.fileCount.innerHTML = `Saved (${count}) new files`;
  }

  onMainUploadClick(ev) {
    this.mainUploadButtons.forEach(button => (button.disabled = false));
    ev.target.disabled = true;
    this.mainUploadIdInput.value = ev.target.dataset.uploadId;
  }

  getFileInputName(file) {
    const prefix = this.data.get("name");
    const postfix = "[upload]";
    const fileIndex = this.fileInputTargets.length + this.newFileCount;

    return `${prefix}[${fileIndex}]${postfix}`;
  }
}
