import React from "react";
import Select from "react-select";
import Creatable from "react-select/creatable";

const customThemeFn = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#2A4365", // blue-900
    primary75: "#2B6CB0", // blue-700
    primary50: "#4299E1", // blue-500
    primary25: "#90CDF4", // blue-300
    neutral0: "#FFFFFF", // white
    neutral5: "#F7FAFC", // gray-100
    neutral10: "#EDF2F7", // gray-200
    neutral20: "#E2E8F0", // gray-300
    neutral30: "#CBD5E0", // gray-400
    neutral40: "#A0AEC0", // gray-500
    neutral50: "#718096", // gray-600
    neutral60: "#4A5568", // gray-700
    neutral70: "#2D3748", // gray-800
    neutral80: "#1A202C", // gray-800
    neutral90: "#000000", // black
    danger: "#C53030", // red-700
    dangerLight: "#F56565", // red-500
  },
});

// source: https://github.com/bevacqua/fuzzysearch
function fuzzySearch(needle, haystack) {
  var hlen = haystack.length;
  var nlen = needle.length;
  if (nlen > hlen) {
    return false;
  }
  if (nlen === hlen) {
    return needle === haystack;
  }
  outer: for (var i = 0, j = 0; i < nlen; i++) {
    var nch = needle.charCodeAt(i);
    while (j < hlen) {
      if (haystack.charCodeAt(j++) === nch) {
        continue outer;
      }
    }
    return false;
  }
  return true;
}

function filterOption(options, query) {
  return query.split(" ").reduce((matched, token) => {
    return matched && fuzzySearch(token, options.label);
  }, true);
}

export default props => {
  var options =
    {
      isRtl: document.dir == 'rtl',
      isClearable: true,
      theme: customThemeFn,
      menuPortalTarget: document.body,
      style: { menuPortal: base => ({...base, zIndex: 9999})},
      filterOption: filterOption,
      placeholder: document.dir == "rtl" ? "اختر ..." : "Select...",
      formatCreateLabel: input => document.dir == "rtl" ? `أنشئ: ${input}` : `Create ${input}`,
      noOptionsMessage: _input => document.dir == "rtl" ? "لا يوجد خيارات" : "No options",
    }

  if (props.isCreatable) {
    return (
      <Creatable {...props} {...options} />
    );
  } else {
    return (
      <Select {...props} {...options} />
    );
  }
};
